import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SideBar from '../layout/sideBar';
import Footer from '../layout/footer';
import Paginate from '../layout/pagination';
import Header from '../layout/header';
import Contact from '../box-layout/contact';


const title = "Hello...";
const aftertitle = "World Recend Form";
const beforetitle = " Form";
const spantitle = "My Contact";
const btntext = "View My Portfolio";

class ContactPage extends Component {
    render() {
        return (
            <div>
                <div className="shape-top"></div>
                <div className="shape-bottom"></div>
                <section className="banner page-header">
                    <div className="banner-area">
                        <div className="container">
                            <div className="section-wrapper banner-wrapper">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-12">
                                        <div className="banner-content">
                                            <h1>{title}</h1>
                                            <h1>{aftertitle} <span>{spantitle}</span> {beforetitle}</h1>
                                            <a className="btn" href="#" download><span>{btntext}</span><img src="assets/images/icon/btn-1.png" alt="icon" className="img-fluid" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="banner-thumb">
                                            <img src="assets/images/banner/02.png" alt="banner" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="box-shadow-style">
                    <Contact />
                </div>
                <Footer />
            </div>
        );
    }
}

export default ContactPage;